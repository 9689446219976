@import '../variables';

/* ---------------------------------------------------
    PROFILE MENU STYLE
----------------------------------------------------- */

.profile-menu-mask {
    height: calc(100vh - #{$header-height});
    position: absolute;
    width: 100%;
    z-index: 9999;
    background: rgba(0,0,0,.5);
    display: none;
    transition: all 0.5s;

    &.is-open {
      display: block;
      transition: all 0.5s;
    }

  }

  .profile-menu-container {
    min-width: $profile-menu-width;
    max-width: $profile-menu-width;
    background-color: #fff;
    transition: all 0.5s;
    text-align: left;
    padding: 20px 0px;
    margin-left: calc(100%);
    position: absolute;
    z-index: 99999;
    top: 0px;
    height: 90%;

    &.is-open {
      transition: 0.5s;
      margin-left: calc(100% - #{$profile-menu-width});
    }

    & .profile-menu-header .close {
      width: 30px;
      height: 30px;

      &:hover {
        cursor: pointer;
      }

    }

    & .profile-menu {
      height: calc(100vh - #{$header-height});
      & .nav-item .form-control {
        padding: 0.5rem 1rem;
      }
      & li {
        text-transform: uppercase;
        border-bottom: $profile-menu-border-color;

        &:first-of-type {
          border-top: $profile-menu-border-color;
          margin-top: 20px;
        }
      }
    }

    & .nav-item:hover {
      background: map-get($theme-colors, "lightgray");
    }
  }
