/* ---------------------------------------------------
    INVITE USERS STYLES
----------------------------------------------------- */

.invite-user-body{
    height:80vh;
}

.addUser{
    display: flex;


    .tables{
        height:500px;
    }

    .MyProfile-header{
        flex:1 1;
    }

    .table{
        border:0px;
    }

    .card-one{
        flex:2;

        form{
            flex:1;
        }

    }

    .card-two{
        flex:3;
    }

    .trash-btn{
        border: 0px;
        background-color: transparent;
        padding: 0px;
        margin: 0px;
    }
}

.searchUser{
    .scrollable{
        max-height: 90%;
    }

    input.form-control {
       padding-right: 26px; 
    }

    .form-icon {
        margin-right: 7px;
    }

    .table thead th {
        border-top-width: 0px;
        border-bottom-width: 1px;
    }

    .table thead th:first-child {
        border-left-width: 0px;
    }

    .table thead th:last-child {
        border-right-width: 0px;
    }

    .table tbody td:first-child {
        border-left-width: 0px;
    }

    .table tbody td:last-child {
        border-right-width: 0px;
    }
}

.scrollable{
    max-height: 100%;
    overflow: auto;
}

.userPermissions{
    thead {
      td{
        cursor: pointer;
      }
    }

    td{
        text-align: center;
        width:210px;

    }

    .selected{
        border: 0px;

        border-right: solid 5px black;
        border-left: solid 5px black !important;
        border-bottom: solid 1px #dee2e6;

        &.last {
            border-bottom: solid 5px black;
        }

        &.first{
            border-top: solid 5px black;
        }
    }
}
