
@import '../variables';

/* ---------------------------------------------------
    EXPORT REPORT DIALOG STYLE
----------------------------------------------------- */

.ExportReportDialog {   

}

.print-modal-header {
    & .modal-title {
        width: 100%;
    }

    & .report-title {
        text-transform: capitalize;
    }
}

.print-modal-body {
    max-height: 300px;

    & h3 {
        font-size: 20px;
    }

    & h4 {
        font-size: 14px;
    }

    .report-list {
        max-height: 200px;
        background-color: #ddd;
    }
    
    .report-options .btn {
        min-width: 100px;
        width: 100%;
    }
}

