
@import '../variables';

.AlertBox {


}

.checkmark {
animation-name: spin;
animation-delay: .5s;
animation-duration: 500ms;
animation-iteration-count: 1;
animation-timing-function: linear; 
    
}

.checkmark i {
    font-size: 60px;
    font-weight: 600;
    // color: map-get($theme-colors, "success");
}

i.cil-check-alt {
    color: map-get($theme-colors, "success");
}

i.cil-mood-very-bad {
    color: map-get($theme-colors, "danger");
}

i.cil-x-circle {
    color: map-get($theme-colors, "danger");
}

i.cil-warning{
    color: map-get($theme-colors, "secondary");
}


@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

.fade-in {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}