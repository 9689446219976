@import '../variables';

/* ---------------------------------------------------
    NOTIFICATIONS MENU STYLE
----------------------------------------------------- */

.notifications-menu-mask {
    height: calc(100vh - #{$header-height});
    position: absolute;
    width: 100%;
    z-index: 9999;
    background: rgba(0,0,0,.5);
    display: none;
    transition: all 0.5s;
  
    &.is-open {
      display: block;
      transition: all 0.5s;
    }
  
  }
  
  .notifications-menu-container {
    transition: all 0.5s;
    text-align: left;
    position: absolute;
    z-index: 99999;
    top: 0px;
    min-width: 500px;
    margin-top: -100%;
  
    &.is-open {
      transition: 0.5s;
      margin-top: $header-height;
    }
  
    .notification-content {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    
      & .notification-bottom-text {
        font-size: 1.2em;
      }
    
    }
  
  }
