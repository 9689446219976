/* ---------------------------------------------------
    VARIABLES
----------------------------------------------------- */
/*
Override variables here to customize bootstrap theme
https://getbootstrap.com/docs/4.0/getting-started/theming/

*/

$font-family-sans-serif: 'Open Sans',
sans-serif;

$theme-colors: (
  "primary": #2F353A,
  "secondary": #F9A61C,
  "danger": #D84747,
  "success": #39B64A,
  "darkgray": #44464B,
  "mediumgray": #919295,
  "lightgray": #DBDBDD,
  "blue": #0070B9,
);

$btn-font-weight: bold;

$btn-border-radius: 40px;
$btn-padding-y: 5px;
$btn-border-width: 2px;

$input-padding-y: 0.43rem;
$input-border-width: 1px;
$input-border-radius: 0;
$input-placeholder-color: theme-color("mediumgray");

$header-height: 50.2px;
$footer-height: 48px;
$sidebar-width: 250px;
$profile-menu-width: 250px;
$sidebar-border: .5px solid theme-color("darkgray");
$profile-menu-border-color: .5px solid #eee;

$enable-transitions: true;

$grid-breakpoints: (xxs: 0,
  xs: 400px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px) !default;
