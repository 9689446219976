@import '../variables';

/* ---------------------------------------------------
    TOPBAR STYLE
----------------------------------------------------- */

.logo {
  max-width: 250px;

  & img {
    width: 72%;
  }
}

.toggle-menu:hover {
  cursor: pointer;
}

.toggle-profile-menu {
  position: absolute;
  right: 15px;
  width: 20px;
  text-align: center;
}

.topbar-user {

  position: absolute;
  right: 50px;

 /*& .profile-link {
    border-left: .5px solid #eee;
  }*/

  .badge {
    transform: translate(-6px, -7px);
  }

  & .notification:hover {
    cursor: pointer;
  }

  & .profile-image {
    height: 30px;
    width: 30px;
    font-size: 15px;
    margin-right: 10px;
  }
}


@media only screen and (max-width: map-get($grid-breakpoints, md)) {

  .logo img {
    margin-left: 30px;
  }


}

@media only screen and (max-width: map-get($grid-breakpoints, sm)) {

  .topbar-user {

    .profile-image {
      margin-right: -10px;
    }

    .profile-name {
      display: none;
    }

  }

}